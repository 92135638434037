.heading {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
  }

.CardsComponents-container {
    width: 100%;
    height: 100vh; /* Take up full viewport height */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    position: relative; 
    margin:-100px 0;
  }
  
  .slides-container {
    position: relative; /* Ensure relative positioning for the next and previous buttons */
    display: flex;
    width: 100%; /* Ensure the slides take up the full width */
    justify-content: center;
    transition: transform 1.2s ease;
  }
  
  .slide {
    width: calc(33.33% - 20px);
    height: 550px;
    background-color: antiquewhite;
    margin: 0 10px;
    text-align: center;
    position: relative; /* Ensure the slide is positioned relatively */
  }
  
  .slide img {
    width: 100%;
    height: 60%;
    object-fit: cover;
    border-radius: 8px;
  }
  
  .slide-content {
    padding: 20px;
  }
  
  .slide h2 {
    margin-top: 10px;
    font-size: 20px;
    font-weight: bold;
  }
  
  .slide p {
    margin-top: 5px;
    font-size: 16px;
    color: #555;
  }
  
  .prev,
  .next {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 24px;
    color: #555;
    padding: 10px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .prev {
    left: 10px;
  }
  
  .next {
    right: 10px;
  }
  
  .prev:hover,
  .next:hover {
    color: #000;
  }

  @media (max-width: 480px) {
    .slide {
      width: calc(100% - 20px);
      height: 300px;
      margin: 10px 0;
    }
  
    .slide h2 {
      font-size: 16px;
    }
  
    .slide p {
      font-size: 14px;
    }
  
    .prev,
    .next {
      font-size: 16px;
      padding: 5px;
    }
  }
  