nav{
    width:100%;
    /* background: #333; */
    color : #fff;
    padding:4px 0;
    position:fixed;
    top: 0;
    left:0;
    display: flex;
    align-items: center;
    justify-content:space-between;
    z-index:10;
}

.logo{
    width: 160px;
    height: 70px;
    size: 5%;
    border-radius:10px;

}


nav ul li {
    display:inline-block;
    list-style:none;
    margin:5px 20px;
    font-size:16px;
}
.dark-nav{
    background:#644117;
    transition:0.5s;
}
.menu_icon{
    display: none;
}

.tryBtn {
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0px;
    background-color: #cd853f;
    padding: 9px 12px 9px 12px;
    border: none;
    color: white !important;
    border-radius: 4px;
    cursor: pointer;
}
.text-white {
    color: #fff !important;
}

.contactusBtn {
    margin-left: -30px; 
    margin-top: 5px; 
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0px;
    fill: #cd853f;
    color: #cd853f !important;
    background-color: rgba(241, 90, 38, 0);
    border-style: solid;
    border-width: 2px 2px 2px 2px;
    border-color: #cd853f;
    padding: 5px;
    cursor:pointer;
}
.contact_us_text{
    color: #cd853f;
    padding: 4px 10px;
}
.linkedin-icon-container {
    margin-left: 10px; 
    margin-top: 5px; 
  }
  
  .linkedin-icon {
    margin-left: -10px;
    vertical-align: middle;
  }

/* =========media auery======== */

@media (max-width:1000px){
    .logo{
        width:140px;
    }
    nav ul li {
        margin:10px 15px
    }
}
@media (max-width:840px){
    nav{
        padding:15px 0;
    }
    nav ul{
        position:fixed;
        top:0;
        right:0px;
        bottom:0;
        background:#212EA0;
        z-index:-1;
        width: 200px;
        padding-top: 70px;
        transition: 0.5s;

    }
    nav ul li{
        display: block;
        margin:25px 40px;
    }
    .menu_icon{
        display: block;
        width: 30px;
        cursor: pointer;
    }
    .hide-mobile-menu{
        right: -200px;
    }
}