.title{
    text-align:center;
    color: #212EA0;
    font-size: 15PX;
    font-weight: 600;
    text-transform: uppercase;
    margin: 110px 0 30px;
}
.title h2{
    font-size: 32px;
    color: #000F38;
    margin-top:5px;
    text-transform: none;
}
