.dropdown li {
    cursor: pointer;
  }
  
  .menu {
    position: absolute;
    list-style-type: none;
    margin: 20px 0px;
    padding: 1px;
    border: 1px solid rgb(250, 227, 227);
    width: 170px; 
    height :605px;
    background-color: white;
    border-radius: 15px;
  }
  
  .menu-item {
    color: black;
  }
  

  .menu-item:hover {
    color: red;
    cursor: pointer;
  }
  