
.heading {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
}
.about {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  margin: 50px auto;
  padding: 20px;
  background-color: #f7f7f7;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden; /* Adding overflow hidden to prevent scrollbar */
}
  
  .about h2 {
    color: #0d2446; 
    font-size: 24px; 
    margin-bottom: 20px; 
  }
  
  .contact-wrap {
    flex-basis: 50%;
    padding-left: 80px; 
  }
  
  .about-left {
    flex-basis: 50%; 
  }

  .lists{
    padding: 5px;
  }
  .lists li{
    padding: 8px;
  }
  
  .about-img {
    width: 100%; 
    border-radius: 10px; 
  }
  

  .sec-title {
    margin-bottom: 20px;
  }
  
  .desc {
    margin-bottom: 20px; 
  }
  
  .ol {
    margin-bottom: 20px; 
  }
  

/* -------media query ------- */
  @media (max-width: 768px) {
    .about {
      flex-direction: column; 
    }
  
    .contact-wrap,
    .about-left {
      flex-basis: 100%;
      padding: 0 20px; 
    }
  
    .about h2 {
      text-align: center; 
    }
  }
  